import React, { useEffect, useState } from 'react';
import { resolveBlock } from '../../utils/resolve';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { genUrls, client } from './utils';
import GroupPage from '../group-page/group-query';

export const GroupPagePreview = ({ document, langCode }) => {
	const [ sanityData, setSanityData ] = useState(undefined);

	const formatData = ({ page, linkedMembers }) => {
		const res = {
			allSanityGroupPage: {
				edges: [
					{
						node: {
							members: page.members.map((m) => ({
								id: m._id,
								...m,
								content: {
									...m.content,
									portfolio: {
										...m.content.portfolio,
										positionClaim: {
											claim: m.content.portfolio.positionClaim[langCode + '_claim'],
											position: m.content.portfolio.positionClaim[langCode + '_position']
										},
										videoList:
											m.content.portfolio.videoList &&
											m.content.portfolio.videoList.map((v) => ({
												...v,
												image: v.image
													? {
															asset: {
																gatsbyImageData: getGatsbyImageData(
																	v.image.asset._ref,
																	{},
																	{
																		projectId: 'qe5nq4ui',
																		dataset: 'production'
																	}
																)
															}
														}
													: undefined
											})),
										imageList:
											m.content.portfolio.imageList &&
											m.content.portfolio.imageList.map((i) => ({
												...i,
												asset: i.asset
													? {
															gatsbyImageData: getGatsbyImageData(
																i.asset._ref,
																{},
																{
																	projectId: 'qe5nq4ui',
																	dataset: 'production'
																}
															)
														}
													: undefined
											}))
									}
								}
							}))
						}
					}
				]
			},
			allSanityLinkedMembers: { nodes: linkedMembers }
		};
		return res;
	};

	useEffect(async () => {
		let subscription;
		const query = `*[_id == '${document}'] { ..., 'members':members[]-> }  `;

		const linkedMembers = (await client.fetch("*[_type == 'linkedMembers'] {'a':a->,'b':b->} ")).map((m) => ({
			a: { ...m.a, id: m.a._id },
			b: { ...m.b, id: m.b._id}
		}));

		client.fetch(query).then((response) => {
			const page = response[0];
			setSanityData({ page, linkedMembers });
		});
		subscription = client.listen(query).subscribe((response) => {
			const page = response.result;
			setSanityData({ page, linkedMembers });
		});

		return () => subscription && subscription.unsubscribe();
	}, []);

	return (
		<div>
			{sanityData && (
				<GroupPage
					location={{ pathname: `/${langCode}` }}
					data={formatData(sanityData)}
					pageContext={genUrls('/preview/groupPage', document)}
				/>
			)}
		</div>
	);
};
