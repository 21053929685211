import React, { useEffect, useState } from 'react';
import { resolveBlock } from '../../utils/resolve';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { genUrls, client } from './utils';
import TerminePage from '../termine-page/termine-query';

export const TerminePreview = ({ document, langCode }) => {
	const [ sanityData, setSanityData ] = useState(undefined);

	const formatData = (page) => {
		const res = {
			allSanityDatesPage: {
				nodes: [
					{
						dates: page.dates.map((d) => ({
							...d,
							play: {
								playIntl: {
									name: d.play.playIntl[langCode + '_name'],
									slug: d.play.playIntl[langCode + '_slug'].current
								}
							},
							note: {
								note: d.note ? d.note[langCode + '_note'] : undefined
							},
							launchList: d.launchList.map((l) => ({
								...l,
								hint: l.hint ? { note: l.hint[langCode + '_note'] } : undefined
							}))
						}))
					}
				]
			}
		};
		return res;
	};

	useEffect(() => {
		let subscription;
		const query = `*[_id == '${document}'] { 'dates':dates[]{...,'play':play->} } `;
		client.fetch(query).then((response) => {
			const play = response[0];
			setSanityData(play);
		});
		subscription = client.listen(query).subscribe((response) => {
			const play = response.result;
			setSanityData(play);
		});

		return () => subscription && subscription.unsubscribe();
	}, []);

	return (
		<div>
			{sanityData && (
				<TerminePage
					location={{ pathname: `/${langCode}` }}
					data={formatData(sanityData)}
					pageContext={genUrls('/preview/datesPage', document)}
				/>
			)}
		</div>
	);
};
