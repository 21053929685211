import sanityClient from '@sanity/client';

export const genUrls = (root, id) => {
    const langs = ['de', 'fr', 'en', 'es'];
    return langs.reduce((acc, l) => ({ ...acc, [l]: `${root}/${l}/${id}` }), {});
};

export const client = sanityClient({
	projectId: 'qe5nq4ui',
	dataset: 'production',
	useCdn: false, // `false` if you want to ensure fresh data
	withCredentials: true
});