/** @jsx jsx */
import { jsx} from 'theme-ui'
import React, { useEffect, useState } from 'react';
import { resolveBlock } from '../../utils/resolve';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { genUrls, client } from './utils';
import StueckePage from '../stuecke-page/stuecke-query';
import { FlowerFaceComp } from '../home-page/faces';

// allSanityStueckePage {
// 			nodes {
// 				members {
// 					playIntl {
// 						name(langCode: $langCode)
// 						slug(langCode: $langCode)
// 					}
// 				    image {
// 						asset {
// 							gatsbyImageData
// 						}
// 					}
// 				}
// 				title {
// 					note(langCode: $langCode)
// 				}
// 			}
// 		}

const getImage = (ref) => {
	return getGatsbyImageData(
		ref,
		{},
		{
			projectId: 'qe5nq4ui',
			dataset: 'production'
		}
	);
};

export const MemberPreview = ({ document, langCode }) => {
	const [ sanityData, setSanityData ] = useState(undefined);

	useEffect(() => {
		let subscription;
		const query = `*[_id == '${document}'] {content {face, flower}} `;

		const setData = (data) => {
			setSanityData({
				flower: getImage(data.content.flower.asset._ref),
				face: getImage(data.content.face.asset._ref)
			});
		};

		client.fetch(query).then((response) => {
			const play = response[0];
			setData(play);
		});
		subscription = client.listen(query).subscribe((response) => {
			const play = response.result;
			setData(play);
		});

		return () => subscription && subscription.unsubscribe();
	}, []);

	return (
		<div
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				width: '100vw',
				textAlign: 'center'
			}}
		>
			{sanityData && <FlowerFaceComp face={sanityData.face} flower={sanityData.flower} width={'400px'} />}
		</div>
	);
};
