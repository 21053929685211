import React, { useEffect, useState } from 'react';
import PostPage from '../post-page/post-query';
import { resolveBlock } from '../../utils/resolve';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { genUrls, client } from './utils';

export const PostPreview = ({ document, langCode }) => {
	const [ sanityData, setSanityData ] = useState(undefined);

	const formatData = ({ post, members }) => {
		const res = {
			sanityPost: {
				image: post.image
					? {
							asset: {
								gatsbyImageData: getGatsbyImageData(
									post.image.asset._ref,
									{},
									{
										projectId: 'qe5nq4ui',
										dataset: 'production'
									}
								)
							}
						}
					: undefined,
				postIntl: {
					name: post.postIntl[langCode + '_name'],
					intlContent: resolveBlock(
						post.postIntl,
						langCode,
						'content',
						members.map((m) => ({ ...m, id: m._id }))
					)
				}
			},
			allSanityPost: { nodes: [] }
		};
		return res;
	};

	useEffect(() => {
		let subscription;
		const query = `*[_id == '${document}'] { ... }`;
		const queryMember = `*[_type == 'member'] { ... }`;
		client.fetch(queryMember).then((members) => {
			const setData = (post) => {
				setSanityData({ post, members });
			};
			client.fetch(query).then((response) => {
				const post = response[0];
				setData(post);
			});
			subscription = client.listen(query).subscribe((response) => {
				const post = response.result;
				setData(post);
			});
		});

		return () => subscription && subscription.unsubscribe();
	}, []);

	return (
		<div>
			{sanityData && (
				<PostPage
					location={{ pathname: `/${langCode}` }}
					data={formatData(sanityData)}
					pageContext={genUrls('/preview/post', document)}
				/>
			)}
		</div>
	);
};
