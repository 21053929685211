/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { Router, useLocation } from '@reach/router';

import { PostPreview } from '../components/preview/PostPreview';
import { PlayPreview } from '../components/preview/PlayPreview';
import { StueckePagePreview } from '../components/preview/StueckePagePreview';
import { GroupPagePreview } from '../components/preview/GroupPagePreview';
import { TerminePreview } from '../components/preview/TerminePreview';
import {MemberPreview } from '../components/preview/MemberPreview'

const NotFound = () => <div>Für diese Inhalte gibt es keine Vorschau</div>;

const Previews = () => {
	const location = useLocation();
	if (location.pathname.match(/\/drafts\./))
		return (
			<div sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: '100vh',
				width: '100vw',
				fontSize: 4,
				textAlign: "center",
			}}>
				<div>
				<p>Du versuchts einen unveröffentlichten Inhalt zu öffnen!</p>
				<p>Veröffentliche den Inhalt mit dem Publish-Button und öffne die Vorschau erneut.</p></div>
			</div>
		);

	return (
		<div>
			<Router>
				<PostPreview path="/preview/post/:langCode/:document" />
				<PlayPreview path="/preview/play/:langCode/:document" />
				<StueckePagePreview path="/preview/stueckePage/:langCode/:document" />
				<TerminePreview path="/preview/datesPage/:langCode/:document" />
				<GroupPagePreview path="/preview/groupPage/:langCode/:document" />
				<MemberPreview path="/preview/member/:langCode/:document" />
				<NotFound path="/preview/*" />
			</Router>
		</div>
	);
};

export default Previews;
