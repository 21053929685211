import React, { useEffect, useState } from 'react';
import PlayPage from '../playinfo-page/playinfo-query';
import { resolveBlock } from '../../utils/resolve';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { genUrls, client } from './utils';

export const PlayPreview = ({ document, langCode }) => {
	const [ sanityData, setSanityData ] = useState(undefined);

	const formatData = ({ play, members }) => {
		const res = {
			sanityPlay: {
				image: play.image
					? {
							asset: {
								gatsbyImageData: getGatsbyImageData(
									play.image.asset._ref,
									{},
									{
										projectId: 'qe5nq4ui',
										dataset: 'production'
									}
								)
							}
						}
					: undefined,
				playIntl: {
					name: play.playIntl[langCode + '_name'],
					rawDsc: resolveBlock(play.playIntl, langCode, 'dsc', members.map((m) => ({ ...m, id: m._id })))
				},
				with: play.with
			},
			allSanityDatesPage: { nodes: [] }
		};
		return res;
	};

	useEffect(() => {
		let subscription;
		const query = `*[_id == '${document}'] { image, playIntl, "with":with[]-> } `;
		const queryMember = `*[_type == 'member'] { ... }`;

		client.fetch(queryMember).then((members) => {
			const setData = (play) => {
				setSanityData({ play, members });
			};
			client.fetch(query).then((response) => {
				const play = response[0];
				setData(play);
			});
			subscription = client.listen(query).subscribe((response) => {
				const play = response.result;
				setData(play);
			});
		});

		return () => subscription && subscription.unsubscribe();
	}, []);

	return (
		<div>
			{sanityData && (
				<PlayPage
					location={{ pathname: `/${langCode}` }}
					data={formatData(sanityData)}
					pageContext={genUrls('/preview/play', document)}
				/>
			)}
		</div>
	);
};
