import React, { useEffect, useState } from 'react';
import { resolveBlock } from '../../utils/resolve';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { genUrls, client } from './utils';
import StueckePage from '../stuecke-page/stuecke-query';

// allSanityStueckePage {
// 			nodes {
// 				members {
// 					playIntl {
// 						name(langCode: $langCode)
// 						slug(langCode: $langCode)
// 					}
// 				    image {
// 						asset {
// 							gatsbyImageData
// 						}
// 					}
// 				}
// 				title {
// 					note(langCode: $langCode)
// 				}
// 			}
// 		}

export const StueckePagePreview = ({ document, langCode }) => {
	const [ sanityData, setSanityData ] = useState(undefined);

	const formatData = (page) => {
		const res = {
			allSanityStueckePage: {
				nodes: [
					{
						members: page.members.map((play) => ({
							playIntl: {
								name: play.playIntl[langCode + '_name'],
								slug: play.playIntl[langCode + '_slug'].current
							},

							image: play.image
								? {
										asset: {
											gatsbyImageData: getGatsbyImageData(
												play.image.asset._ref,
												{},
												{
													projectId: 'qe5nq4ui',
													dataset: 'production'
												}
											)
										}
									}
								: undefined
						})),
						title: { note: page.title[langCode + '_title'] }
					}
				]
			}
		};
		return res;
	};

	useEffect(() => {
		let subscription;
		const query = `*[_id == '${document}'] { ..., 'members':members[]-> }`;
		client.fetch(query).then((response) => {
			const play = response[0];
			setSanityData(play);
		});
		subscription = client.listen(query).subscribe((response) => {
			const play = response.result;
			setSanityData(play);
		});

		return () => subscription && subscription.unsubscribe();
	}, []);

	return (
		<div>
			{sanityData && (
				<StueckePage
					location={{ pathname: `/${langCode}` }}
					data={formatData(sanityData)}
					pageContext={genUrls('/preview/stueckePage', document)}
				/>
			)}
		</div>
	);
};
