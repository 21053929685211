// function nameToUrl(str) {
// 	return encodeURI(str.toLowerCase().replace(/ /g,"_"));
// }

function slugify(str)
{
    str = str.replace(/^\s+|\s+$/g, '');

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '') 
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-') 
    // Collapse dashes
    .replace(/-+/g, '-'); 

    return str;
}

function fullName(source) {
	const { firstName, lastName } = source;
	const name = firstName === undefined || lastName === undefined || firstName === '' || lastName === '' ? source.name : `${firstName} ${lastName}`;
	return name;
}

function resolve_MemberLink(data, entries) {
	return data.map((block) => {
		if (block.markDefs) {
			const markDefs = block.markDefs.map((mDef) => {
				if (mDef._type === 'memberLink') {
					const member = [...entries.filter((m) => m.id === mDef.reference?._ref)][0];
					// console.log('\n ----- \n' + JSON.stringify(member, null, 2) + '\n ----- \n');
					if (!member || !member.content)
						return mDef;

					const { flower, face } = member.content;
					const url = slugify(fullName(member))
					
					return { ...mDef, reference: { flower, face, bioSlug: member.isBioVisible ? url : undefined } };
				}
				return mDef;
			});
			return { ...block, markDefs };
		}
		return block;
	});
}

function resolveBlock(source,langCode,field,entries) {
	const content_of_lang = source[langCode + `_${field}`];
	if (content_of_lang)
		return { raw: resolve_MemberLink(source[langCode + `_${field}`], entries) };
	else {
		const upper_langCode = langCode.charAt(0).toUpperCase() + langCode.slice(1);
		const langs = ['De', 'Fr', 'Es', 'En'].filter((v) => v !== upper_langCode);
		return langs.reduce(
			(acc, l) => {
				const cont = source[l.toLowerCase() + `_${field}`];
				return ({
					...acc,
					[`raw${l}`]: cont ? resolve_MemberLink(cont, entries) : cont
				});
			},
			{}
		);
	}

}

exports.resolveBlock = resolveBlock;

function sanityBlock_by_langCode(field) {
	return {
		type: 'IntlContent',
		args: {
			langCode: 'String!'
		},
		resolve: async (source, args, context, info) => {
			const { langCode } = args;
			//console.log(`_raw${upper_langCode}${field}`)
			//	console.log(JSON.stringify(args, null, 2)+'\n-----\n')
			//console.log(JSON.stringify(source, null, 2))
			const { entries } = await context.nodeModel.findAll({ type: `SanityMember` });

			return resolveBlock(source,langCode,field,entries)

			// entries.forEach((entry) => {
			// 	console.log(JSON.stringify(entry, null, 2));
			// });

		}
	};
}

exports.sanityBlock_by_langCode = sanityBlock_by_langCode;

const gen_excerpt = (raw) => {
	const excerptLength = 250;

	let pointer = 0;

	const shorten_children = (children) => {
		let ret = [];
		for (const child of children) {
			const { text } = child;
			//pointer += text.length;
			if (pointer + text.length <= excerptLength) {
				ret.push(child);
				pointer += text.length;
			} else {
				let str = child.text.substring(0, excerptLength - pointer);
				const lastIndex = str.lastIndexOf(' ');
				str = str.substring(0, lastIndex);
				ret.push({ ...child, marks: [...child.marks, 'last'], text: str });
				pointer = excerptLength;
				break;
			}
		}
		return ret;
	};

	let arr = [];
	for (const value of raw) {
		if (value._type !== 'block')
			continue;
		//const children = value.children.
		arr.push({ ...value, children: shorten_children(value.children) });
		if (pointer >= excerptLength)
			break;
	}

	return arr;
};

function sanityExcerptBlock_by_langCode(excerptField, fullField) {
	return {
		type: 'IntlContent',
		args: {
			langCode: 'String!'
		},
		resolve: (source, args, context, info) => {
			const { langCode } = args;
			//console.log(`_raw${upper_langCode}${field}`)
			//	console.log(JSON.stringify(args, null, 2)+'\n-----\n')
			//console.log(JSON.stringify(source, null, 2))
			const content_of_lang = source[langCode + `_${excerptField}`];
			if (content_of_lang)
				return { raw: content_of_lang };

			const full_content_of_lang = source[langCode + `_${fullField}`];
			if (full_content_of_lang)
				return { raw: gen_excerpt(full_content_of_lang) };

			const upper_langCode = langCode.charAt(0).toUpperCase() + langCode.slice(1);
			const langs = ['De', 'Fr', 'Es', 'En'].filter((v) => v !== upper_langCode);
			return langs.reduce((acc, l) => {
				let s = source[l.toLowerCase() + `_${excerptField}`];
				if (!s) {
					const full = source[l.toLowerCase() + `_${fullField}`];
					s = full && gen_excerpt(full);
				}
				return { ...acc, [`raw${l}`]: s };
			}, {});
		}
	};
}

exports.sanityExcerptBlock_by_langCode = sanityExcerptBlock_by_langCode;

exports.slugify = slugify;

exports.fullName = fullName;